import styled from "styled-components";
import { MainWrapper } from "@/lib/styles";
import { pageTop, pageTopBackButton } from "@/lib/styles/helpers";

export const Container = styled.div<{
  $isBackButton: boolean;
  $isHomepage: boolean;
}>`
  ${({ $isBackButton, $isHomepage }) => {
    if ($isHomepage) {
      return "";
    } else if ($isBackButton) {
      return pageTopBackButton;
    } else {
      return pageTop;
    }
  }};

  position: relative;
`;

export const Wrapper = styled(MainWrapper)``;

export const FullWidthWrapper = styled(MainWrapper)`
  ${({ theme }) => theme.mediaQueries.desktop} {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    max-width: unset;
  }
  @media screen and (min-width: 105rem) {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    max-width: unset;
  }
`;

export const FullWrapper = styled.div``;
