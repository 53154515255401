import InternalLink, { InternalLinkProps } from "./InternalLink";
import ExternalLink, { ExternalLinkProps } from "./ExternalLink";
import { Container } from "./styles";

export type GoBackProps = InternalLinkProps | ExternalLinkProps;

const GoBack: React.FC<GoBackProps> = (props) => {
  return (
    <Container>
      {props._type == "internalLink" && <InternalLink {...props} />}
      {props._type == "externalLink" && <ExternalLink {...props} />}
    </Container>
  );
};

export default GoBack;
