import AppLink, { AppLinkProps } from "@/components/AppLink";
import BackButton from "@/components/BackButton";

export type InternalLinkProps = AppLinkProps & {
  _type: "internalLink";
};

const InternalLink: React.FC<InternalLinkProps> = (props) => {
  const { title, page, params } = props;

  return (
    <AppLink page={page} params={params}>
      <BackButton>{title || undefined}</BackButton>
    </AppLink>
  );
};

export default InternalLink;
