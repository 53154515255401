import {
  createPreviewSubscriptionHook,
  createCurrentUserHook,
} from "next-sanity";
import { ImageLoader } from "next/image";
import createImageUrlBuilder from "@sanity/image-url";
import { config } from "./config";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 **/
export const urlFor = (source: SanityImageSource) =>
  createImageUrlBuilder(config).image(source);

// Set up the live preview subscription hook
export const usePreviewSubscription = createPreviewSubscriptionHook(config);

// Helper function for using the current logged in user account
export const useCurrentUser = createCurrentUserHook(config);

export const imageLoader: ImageLoader = ({ src, width, quality }) => {
  return urlFor(src)
    .width(width)
    .maxWidth(2000)
    .quality(quality || 90)
    .format("webp")
    .url();
};

/**
 * Helper function to return the correct version of the document
 * If we're in "preview mode" and have multiple documents, return the draft
 */
export function filterDataToSingleItem<T extends { _id: string }>(
  data: T | Array<T>,
  preview: boolean
) {
  if (!Array.isArray(data)) {
    return data;
  }

  if (data.length === 1) {
    return data[0];
  }

  if (preview) {
    return data.find((item) => item._id.startsWith(`drafts.`)) || data[0];
  }

  return data[0];
}
