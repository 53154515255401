import BackButton from "@/components/BackButton";

export type ExternalLinkProps = {
  _type: "externalLink";
  title: string | null;
  url: string | null;
  blank: boolean;
};

const ExternalLink: React.FC<ExternalLinkProps> = (props) => {
  const { title, url, blank } = props;
  if (url) {
    return <BackButton href={url} target={`${blank ? "_blank" : ""}`}>{title || undefined}</BackButton>;
  } else {
    return null;
  }
};

export default ExternalLink;
