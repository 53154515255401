import { SITE_URL } from "@/lib/constants";
import { SanityAsset } from "@sanity/image-url/lib/types/types";
import Head from "next/head";
import { useRouter } from "next/router";

export type SEOProps = {
  metaTitle?: string | null;
  metaDesc?: string | null;
  shareTitle?: string | null;
  shareDesc?: string | null;
  slug?: string | null;
  shareGraphic?: {
    asset: {
      url: string;
      metadata: {
        dimensions: {
          width: number;
          height: number;
        };
      };
    } | null;
  } | null;
};

const SEO: React.FC<SEOProps> = (props) => {
  const { metaTitle, metaDesc, shareTitle, shareDesc, shareGraphic } = props;

  const { locale, locales, defaultLocale, asPath } = useRouter();
  const slug = asPath.split("?")[0];
  let canonicalUrl = SITE_URL;
  if (locale !== "it") {
    canonicalUrl += `/${locale}`;
  }
  canonicalUrl += slug;

  return (
    <Head>
      {metaTitle && <title>{metaTitle}</title>}
      {metaDesc && <meta name="description" content={metaDesc} />}
      {shareTitle && <meta property="og:title" content={shareTitle} />}
      {shareTitle && <meta property="twitter:title" content={shareTitle} />}
      {shareDesc && <meta property="og:description" content={shareDesc} />}
      {shareDesc && <meta property="twitter:description" content={shareDesc} />}
      {shareGraphic?.asset?.url && (
        <meta property="og:image" content={shareGraphic.asset.url} />
      )}
      {shareGraphic?.asset?.url && (
        <meta property="twitter:image" content={shareGraphic.asset.url} />
      )}
      {shareGraphic?.asset?.metadata?.dimensions?.width && (
        <meta
          property="og:image:width"
          content={shareGraphic.asset.metadata.dimensions.width?.toString()}
        />
      )}
      {shareGraphic?.asset?.metadata?.dimensions?.height && (
        <meta
          property="og:image:height"
          content={shareGraphic.asset.metadata.dimensions.height?.toString()}
        />
      )}
      <link rel="canonical" href={canonicalUrl} />
      <link rel="alternate" hrefLang="x-default" href={SITE_URL + slug} />
      {locales?.map((locale) => {
        let href = SITE_URL + "/" + locale + slug;
        if (locale === "it") {
          href = SITE_URL + slug;
        }

        return (
          <link key={locale} rel="alternate" hrefLang={locale} href={href} />
        );
      })}
    </Head>
  );
};

export default SEO;
