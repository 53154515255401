import Button from "@/components/Button";
import { SVGArrowLeft } from "@/lib/svg";
import { useTranslation } from "@/lib/translations";
import { useRouter } from "next/router";
import { forwardRef, PropsWithChildren } from "react";
import { ButtonWrapper } from "./styles";

type Props = PropsWithChildren<{
  className?: string;
  href?: string;
  target?: string;
}>;

/**
 * @example
 *
 * <Link href="/" passHref><BackButton></Link>
 * <GoBackButton href="https://google.com" />
 */
const BackButton = forwardRef<HTMLButtonElement, Props>(function GoBackLink(
  props,
  ref
) {
  const { children, className, href, target } = props;

  const { locale = "it" } = useRouter();
  const label = useTranslation("page.goBack", locale);

  return (
    <ButtonWrapper>
      <Button
        ref={ref}
        as="a"
        variant="primaryUnboxed"
        hover={false}
        icon={<SVGArrowLeft />}
        iconPosition="left"
        className={className}
        href={href}
        target={target}
      >
        {children || label || "Indietro"}
      </Button>
    </ButtonWrapper>
  );
});

export default BackButton;
