import { pageMargins } from "@/lib/styles/helpers";
import styled from "styled-components";

export const Column = styled.div`
  &:nth-child(1) {
    border-right: solid 1px ${({ theme }) => theme.colors.lightGrey};
  }
  &:nth-child(3),
  &:nth-child(4) {
    display: none;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-template-columns: repeat(4, 1fr);
    &:nth-child(2),
    &:nth-child(3) {
      border-right: solid 1px ${({ theme }) => theme.colors.lightGrey};
    }
    &:nth-child(3),
    &:nth-child(4) {
      display: block;
    }
  }
`;

export const GridOutlineContainer = styled.div`
  position: fixed;
  z-index: -1;
  inset: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 100rem;
  margin: 0 auto;
  user-select: none;
  ${pageMargins}

  // fix grid not reaching the bottom when content is shorter than 100vh
  min-height: calc(100vh - ${({ theme }) => theme.space.headerMinHeight});

  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
