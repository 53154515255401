import styled from "styled-components";
import { MainWrapper } from "@/lib/styles";

export const ButtonWrapper = styled(MainWrapper)`
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  & > a {
    padding-left: 0;
  }

  &:hover a {
    color: ${({ theme }) => theme.colors.darkMiddle};
  }
`;
