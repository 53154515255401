import type { HeroProps } from "./Hero";
import React from "react";
import dynamic from "next/dynamic";
import { Container, FullWidthWrapper, Wrapper } from "./styles";

// General components
const Accordion = dynamic(() => import("@/components/Accordion"));
const Spacer = dynamic(() => import("@/components/Spacer"));
const CarouselWithCards = dynamic(
  () => import("@/components/CarouselWithCards")
);

// Marketing specific components
const Hero = dynamic(() => import("./Hero"));
const Highlights = dynamic(() => import("./Highlights"));
const Paragraph = dynamic(() => import("./Paragraph"));
const Launch = dynamic(() => import("./Launch"));
const TextWithIllustration = dynamic(() => import("./TextWithIllustration"));
const SubTextWithIllustration = dynamic(() => import("./SubTextWithIllustration"));
const TextWithBulletPoints = dynamic(() => import("./TextWithBulletPoints"));
const TextPodiumWithImage = dynamic(() => import("./TextPodiumWithImage"));
const NumberedParagraphs = dynamic(() => import("./NumberedParagraphs"));
const PaddingRichText = dynamic(() => import("./PaddingRichText"));
const FeaturedProducts = dynamic(() => import("./FeaturedProducts"));
const SectionWithIllustrations = dynamic(
  () => import("./SectionWithIllustrations")
);
const Carousel = dynamic(() => import("./Carousel"));
const BannerQuote = dynamic(() => import("./BannerQuote"));
const Contacts = dynamic(() => import("./Contacts"));
const TextWithLabeledImages = dynamic(() => import("./TextWithLabeledImages"));
const TextWithCards = dynamic(() => import("./TextWithCards"));
const RelatedArticles = dynamic(() => import("./RelatedArticles"));
const TextWithImages = dynamic(() => import("./TextWithImages"));
const HeroHomepage = dynamic(() => import("./HeroHomepage"));

const Block: React.FC<any> = (props) => {
  switch (props._type) {
    case "hero":
      return (
        <>
          <Hero {...props} />
        </>
      );
    case "highlights":
      return (
        <Wrapper>
          <Highlights {...props} />
        </Wrapper>
      );
    case "locationsBlock":
      return <CarouselWithCards {...props} />;
    case "paragraph":
      return (
        <Wrapper>
          <Paragraph {...props} />
        </Wrapper>
      );
    case "carousel":
      // Wrapper doesn't need a wrapper
      return <Carousel {...props} />;
    case "textWithIllustration":
      return (
        <Wrapper>
          <TextWithIllustration {...props} />
        </Wrapper>
      );
    case "subTextWithIllustration":
      return (
        <Wrapper>
          <SubTextWithIllustration {...props} />
        </Wrapper>
      );
    case "textWithBulletPoints":
      return (
        <>
          <TextWithBulletPoints {...props} />
        </>
      );
    case "textPodiumWithImage":
      return (
        <Wrapper>
          <TextPodiumWithImage {...props} />
        </Wrapper>
      );
    case "textWithCards":
      return (
        <Wrapper>
          <TextWithCards {...props} />
        </Wrapper>
      );
    case "textWithImages":
      return (
        <Wrapper>
          <TextWithImages {...props} />
        </Wrapper>
      );
    case "textWithLabeledImages":
      return (
        <Wrapper>
          <TextWithLabeledImages {...props} />
        </Wrapper>
      );
    case "sectionWithIllustrations":
      return (
        <Wrapper>
          <SectionWithIllustrations {...props} />
        </Wrapper>
      );
    case "numberedParagraphs":
      return (
        <>
          <NumberedParagraphs {...props} />
        </>
      );
    case "accordion":
      return (
        <Wrapper>
          <Accordion {...props} />
        </Wrapper>
      );
    case "bannerQuote":
      return (
        <Wrapper>
          <BannerQuote {...props} />
        </Wrapper>
      );
    case "contacts":
      return (
        <Wrapper>
          <Contacts {...props} />
        </Wrapper>
      );
    case "spacer":
      return (
        <Wrapper>
          <Spacer {...props} />
        </Wrapper>
      );
    case "cardCarousel":
      // Carousel doesn't need a wrapper
      return <CarouselWithCards {...props} />;
    case "richText":
      return (
        <Wrapper>
          <PaddingRichText {...props} />
        </Wrapper>
      );
    case "relatedArticles":
      return (
        <Wrapper>
          <RelatedArticles {...props} />
        </Wrapper>
      );
    case "launch":
      return (
        <Wrapper>
          <Launch {...props} />
        </Wrapper>
      );
    case "heroHomepage":
      return (
        <FullWidthWrapper>
          <HeroHomepage {...props} />
        </FullWidthWrapper>
      );
    case "featuredProducts":
      return (
        <Wrapper>
          <FeaturedProducts {...props} />
        </Wrapper>
      );
    default:
      return null;
  }
};

export type PageBuilderProps = {
  blocks: any[];
  isBackButton: boolean;
  isHomepage: boolean;
};

const PageBuilder: React.FC<PageBuilderProps> = ({
  blocks,
  isBackButton,
  isHomepage,
}) => {
  return (
    <Container $isBackButton={isBackButton} $isHomepage={isHomepage}>
      {blocks?.map((block: any) => {
        if (!block._key) return null;
        return <Block key={block._key} {...block} />;
      })}
    </Container>
  );
};

export default PageBuilder;
