import React from "react";
import { Container } from "./styles";
import PageBuilder from "./PageBuilder";
import GoBack, { GoBackProps } from "./GoBack";
import GridOutline from "@/components/GridOutline";

type Props = {
  pageBuilder: any[] | null;
  goBackLink: GoBackProps | null;
  isHomepage: boolean;
};

const Marketing: React.FC<Props> = (props) => {
  const { pageBuilder, goBackLink, isHomepage } = props;
  return (
    <Container>
      <GridOutline />
      {goBackLink && <GoBack {...goBackLink} />}
      {pageBuilder && (
        <PageBuilder
          blocks={pageBuilder}
          isBackButton={goBackLink !== null}
          isHomepage={isHomepage}
        />
      )}
    </Container>
  );
};

export default Marketing;
