import Marketing from "@/components/Marketing";
import SEO from "@/components/SEO";
import {
  usePreviewSubscription,
  filterDataToSingleItem,
} from "@/lib/sanity/client";
import {
  mainLayoutQuery,
  settingsQuery,
  marketingPageQuery,
  seoQuerySchema,
} from "@/lib/sanity/queries";
import { getClient } from "@/lib/sanity/server";
import { mergeWith } from "lodash";
import { GetStaticPaths, GetStaticProps, NextPage } from "next";
import { groq } from "next-sanity";
import { useEffect, useMemo } from "react";
import { z } from "zod";

type UrlQuery = {
  slug: string[];
};

const excludeList = [
  "corporate",
  "investors",
  "products",
  "news",
  "404",
  "500",
  "wishlist",
  "sitemap-corporate.xml",
  "sitemap-investors.xml",
  "sitemap-news.xml",
  "sitemap-pages.xml",
  "sitemap-products.xml",
];

export const getStaticPaths: GetStaticPaths<UrlQuery> = async () => {
  const allSlugsQuery = groq`*[_type == "marketingPage" && defined(slug.current)][0..10].slug.current`;
  const pages = await getClient().fetch<string[] | null>(allSlugsQuery);
  const filteredPages = pages?.filter((slug) => !excludeList.includes(slug));
  return {
    paths: filteredPages?.map((slug) => "/" + slug) || [],
    fallback: "blocking",
  };
};

const marketingPageDataSchema = z.object({
  _id: z.string(),
  seo: seoQuerySchema,
  goBackLinks: z
    .array(
      z.discriminatedUnion("_type", [
        z.object({
          title: z.string().nullable(),
          _type: z.literal("internalLink"),
          params: z.string().nullable(),
          page: z.object({ _type: z.string(), slug: z.string() }).nullable(),
        }),
        z.object({
          title: z.string().nullable(),
          _type: z.literal("externalLink"),
          url: z.string().nullable(),
          blank: z.boolean(),
        }),
      ])
    )
    .nullable(),
  pageBuilder: z
    .array(z.object({ _key: z.string(), _type: z.string() }).passthrough())
    .nullable(),
});
const pageDataSchema = z.array(marketingPageDataSchema);

type MarketingPageData = z.infer<typeof marketingPageDataSchema>;

type Props = {
  preview: boolean;
  data: {
    page: MarketingPageData;
    query: string;
    queryParams: {
      slug: string;
      locale: string;
      defaultLocale: string;
    };
  };
  settings: Record<string, any>;
  layout: Record<string, any>;
};

export const getStaticProps: GetStaticProps<Props, UrlQuery> = async ({
  params,
  preview = false,
  locale = "it",
  defaultLocale = "it",
}) => {
  const slug = params?.slug?.join("/") || "home";

  const queryParams = { slug, locale, defaultLocale };
  const queryResponse = await getClient(preview).fetch(
    marketingPageQuery,
    queryParams
  );
  const data = pageDataSchema.parse(queryResponse);

  // Escape hatch, if our query failed to return data
  if (!data?.length) return { notFound: true };

  // Helper function to reduce all returned documents down to just one
  const page = filterDataToSingleItem<MarketingPageData>(data, preview);

  const [settings, layout] = await Promise.all([
    // Get site settings
    getClient(false).fetch(settingsQuery, {
      locale,
      defaultLocale,
    }),
    // // Get Layout data
    getClient(false).fetch(mainLayoutQuery, {
      locale,
      defaultLocale,
    }),
  ]);

  return {
    props: {
      // Pass down the "preview mode" boolean to the client-side
      preview,
      // Pass down the initial content, and our query
      data: { page, query: marketingPageQuery, queryParams },
      settings,
      layout,
    },
    revalidate: 60,
  };
};

const Page: NextPage<Props> = ({ data, settings, preview }) => {
  const { data: previewData, error } = usePreviewSubscription(data.query, {
    params: data.queryParams,
    // The hook will return this on first render
    // This is why it's important to fetch *draft* content server-side!
    initialData: data.page,
    // The passed-down preview context determines whether this function does anything
    enabled: preview,
  });

  useEffect(() => {
    if (error) console.error(error);
  }, [data, error]);

  // Client-side uses the same query, so we may need to filter it down again
  const page = filterDataToSingleItem(previewData, preview);
  const pageBuilder = page.pageBuilder || null;
  const goBackLink = page.goBackLinks?.[0] || null;

  // Merge page seo with general seo
  const seo = useMemo(() => {
    if (typeof page.seo !== "object") return {};
    if (typeof settings?.seo !== "object") return {};
    return mergeWith(settings.seo, page.seo, (a, b) => b === null ? a : undefined);
  }, [page?.seo, settings?.seo]);

  const isHomepage = page?._id == "home";

  return (
    <>
      {seo && <SEO {...seo} slug={isHomepage ? "" : data?.queryParams?.slug} />}
      {isHomepage && (
        <h1 style={{ opacity: "0", width: "0", height: "0" }}>
          Bormioli pharma
        </h1>
      )}
      <Marketing
        goBackLink={goBackLink}
        pageBuilder={pageBuilder}
        isHomepage={isHomepage}
      />
    </>
  );
};

export default Page;
