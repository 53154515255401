import { forwardRef, memo } from "react";
import { GridOutlineContainer, Column } from "./styles";

type Props = {
  className?: string;
};

/**
 * Draws a generic 4 column grid layout outline.
 * This component is meant to be generic. Any customization should be done in the consumer scope.
 * @example
 *
 * import styled from "styled-components";
 * import GridOutline from "@/components/GridOutline";
 *
 * const StyledGridOutline = styled(GridOutline)`
 *  margin: 4rem;
 *  background-color: yellow;
 * `
 *
 * const MyLayout: React.FC = () => {
 *  return <StyledGridOutline />
 * }
 *
 */
const GridOutline = forwardRef<HTMLDivElement, Props>(function GridOutline(
  props,
  ref
) {
  return (
    <GridOutlineContainer {...props} ref={ref}>
      <Column />
      <Column />
      <Column />
      <Column />
    </GridOutlineContainer>
  );
});

export default memo(GridOutline);
